<template>
  <form @submit.prevent="submitForm">
    <div class="form-control">
      <label for="email">Your Email</label>
      <input type="email" id="email" v-model.trim="email.value" />
    </div>
    <p class="errors" v-if="email.invalid">Please enter a valid email</p>
    <div class="form-control">
      <label for="message">Your Message</label>
      <textarea rows="5" id="message" v-model.trim="message.value" />
    </div>
    <p class="errors" v-if="message.invalid">This field cannot be empty</p>
    <div class="actions">
      <base-button>Send Message</base-button>
    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      email: {
        value: "",
        invalid: false,
      },
      message: {
        value: "",
        invalid: false,
      },
      formInvalid: false,
    };
  },
  methods: {
    submitForm() {
      this.formInvalid = false;

      if (!this.email.value.includes("@")) {
        this.email.invalid = true;
        this.formInvalid = true;
        return;
      }
      if (this.message.value === "") {
        this.message.invalid = true;
        this.formInvalid = true;
        return;
      }

      const request = {
        coachId: this.$route.params.id,
        email: this.email.value,
        message: this.message.value,
      };
      this.$store.dispatch("requests/addRequest", request);

      this.$router.replace("/");
    },
  },
};
</script>

<style scoped>
form {
  margin: 1rem;
  border: 1px solid #ccc;
  border-radius: 12px;
  padding: 1rem;
}

.form-control {
  margin: 0.5rem 0;
}

label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: block;
}

input,
textarea {
  display: block;
  width: 100%;
  font: inherit;
  border: 1px solid #ccc;
  padding: 0.15rem;
}

input:focus,
textarea:focus {
  border-color: #3d008d;
  background-color: #faf6ff;
  outline: none;
}

.errors {
  font-weight: bold;
  color: red;
}

.actions {
  text-align: center;
}
</style>
